import { Controller } from 'stimulus';
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'addBtn', 'taxForm', 'removeBtn'
  ]

  connect(){
    console.log('connection succesfull')
  }

  showTax(e){
    let id = e.currentTarget.value
    this.taxFormTarget.classList.remove('hidden');
    for(const remove of this.removeBtnTargets){
      remove.click()
    }
    this.fetchProduct(id)
  }

  fetchProduct(id){
    Rails.ajax({
      url: '/api/products/' + id,
      type: 'GET',
      success: (product) => {
        for(const tax of product.taxes){
          this.addTax(tax)
        }
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  addTax(tax){
    document.cocoon.insert(this.addBtnTarget, function(item){
      let selectedTax = `${tax.sat_tax_name}|${tax.sat_tax_type}`
      item.querySelector('.sat_tax_name').value = selectedTax
      item.querySelector('.sat_factor_type').value = tax.sat_factor_type
      item.querySelector('.sat_value').value = tax.value
      item.querySelector('.sat_tax_type').value = tax.sat_tax_type
      item.querySelector('.sat_tax_code').value = tax.sat_tax_code
      item.querySelector('.sat_description').value = tax.description
      console.log(tax)
    })
  }
}
