import { Controller } from 'stimulus';
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'business', 'taxResidence', 'tin'
  ]

  connect(){
    console.log('sp contact business connected')
  }

  updateTaxFields(e){
    const id = e.currentTarget.value
    this.fetchBusinessData(id)
  }

  fetchBusinessData(id){
    if(id === '' || id === null || id === undefined) return;

    Rails.ajax({
      url: `/api/businesses`,
      type: 'GET',
      data: `id=${id}`,
      success: function(data){
        let business = data.business
        console.log(data)
        this.taxResidenceTarget.value = business.country
        this.tinTarget.value = business.tin
        this.fireChange()
      }.bind(this),
      error: function(error){
        this.taxResidenceTarget.value = ''
        this.tinTarget.value = ''
        this.fireChange()
        swal(error)
      }.bind(this)
    })
  }

  fireChange(){
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('change', true, true, {})
    this.tinTarget.dispatchEvent(event)
  }
}
