import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ['countryField', 'mexFields', 'usaFields']

  connect() {
    this.toggleNationalityFields()
  }

  toggleNationalityFields(){
    this.usaFieldsTarget.classList.add('hidden')
    this.mexFieldsTarget.classList.add('hidden')

    let option = this.countryFieldTarget.value
    if (option == 'mex') {
      this.mexFieldsTarget.classList.remove('hidden')
      this.disableFields(this.usaFieldsTarget, true)
      this.disableFields(this.mexFieldsTarget, false)
    }else{
      this.usaFieldsTarget.classList.remove('hidden')
      this.disableFields(this.mexFieldsTarget, true)
      this.disableFields(this.usaFieldsTarget, false)
    }
  }

  disableFields(container, value){
    let inputs = container.querySelectorAll('input, select')
    inputs.forEach(function(input) {
      input.disabled = value
    })
  }
}
