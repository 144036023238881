import { Controller } from "stimulus"
import 'flatpickr/dist/flatpickr.css'

export default class extends Controller {
  static targets = []

  connect() {
    this.updateButtons()
  }

  clickAddPermit(){
    document.getElementById('addPermit').click()
  }

  updateButtons(){
    let i = 0;
    let btns = this.element.querySelectorAll('.delete-permit')
    for(const btn of this.element.querySelectorAll('.delete-permit')){
      if(i == 0){
        btn.classList.add('hidden')
      } else {
        btn.classList.remove('hidden')
      }
      i++;
    }

    i = 0;

    let totalPermits = this.totalPermits()
    let icon = document.querySelectorAll('#icon_add_permission')
    console.log(totalPermits)
    for(const btn of this.element.querySelectorAll('.add-permit')){
      if(i > 0){
        btn.classList.add('hidden')
        icon[i].classList.add('hidden')
      } else {
        if(totalPermits < 2){
          btn.classList.remove('hidden');
          icon[i].classList.remove('hidden')
        } else {
          btn.classList.add('hidden')
          icon[i].classList.add('hidden')
        }
      }
      i++;
    }
  }

  totalPermits(){
    let total = 0
    for(const row of this.element.querySelectorAll('.nested-fields.transport_permit')){
      console.log(row, row.style.display)
      if(row.style.display !== 'hidden') total++;
    }

    return total
  }
}
