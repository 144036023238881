import { Controller } from "stimulus"
import SlimSelect from "slim-select"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.url = this.inputTarget.dataset.url
    this.initSelect()
    this.deboucer = null
  }


  search(query, callback) {
    let url = this.url
    let label = this.inputTarget.dataset.textMethod
    let value = this.inputTarget.dataset.valueMethod

    Rails.ajax({
      url: url,
      type: 'GET',
      data: `q=${query}`,
      success: function(data){
        let result = []
        for(const obj of data) {
          let row = {}
          row.text = `${obj[value]} ${obj[label]}`
          row.value = obj[value]
          row.data = obj
          result.push(row)
        }
        callback(result)
      },
      error: function(error){
        callback(false)
      }
    })
  }

  initSelect(){
    this.select = new SlimSelect({
      select: this.inputTarget,
      text: this.inputTarget.dataset.textMethod,
      value: this.inputTarget.dataset.valueMethod,
      searchingText: 'Buscando...',
      searchText: 'No hay registros',
      searchPlaceholder: 'Buscar',
      onChange: function(info){
        let element = this.select.element
        const event = document.createEvent('CustomEvent')
        event.initCustomEvent('remoteSearchChange', true, true, {
          info: info,
          object: element
        })
        window.dispatchEvent(event)
      },
      ajax: function (search, callback) {
        if (search.length < 2) {
          callback('Al menos 2 caracteres')
          return
        }

        console.log('searchingXX')
        if(this.deboucer !== null) clearTimeout(this.debouncer)

        this.debouncer = setTimeout( () => {
          console.log('searching!!')
          this.search(search, callback)
        }, 300)
      }.bind(this)
    })
  }
}
