import { Controller } from 'stimulus';
import Plyr from 'plyr';
import Chart from 'chart.js'
import MicroModal from "micromodal"

export default class extends Controller {
  static targets = ["tutorial", "NewNotice", "btnShow"]
  connect() {
    this.newsNotices()
    this.tutoriales()
  }

  tutorials() {
    const players = Array.from(document.querySelectorAll('.tutorial-video')).map((p) => new Plyr(p));
  }

  newsNotices() {
    let valuesToDisplay = 3
    let cont = 0
    this.NewNoticeTargets.forEach((element) => {
      cont++
      if (cont > valuesToDisplay) {
        element.classList.add('hidden')
      }
    });
    if (this.NewNoticeTargets.length <= valuesToDisplay) {
      this.btnShowTarget.classList.add('hidden')
    }
    this.showButtonMore()
  }

  showHideNews(event) {
    event.preventDefault()
    let textBtn = this.btnShowTarget.querySelector('span')

    this.btnShowTarget.classList.toggle("show-All")
    if (this.btnShowTarget.classList.contains('show-All')) {
      this.showAllnewsNotices()
      textBtn.textContent = 'Mostrar menos'
    } else {
      this.newsNotices()
      textBtn.textContent = 'Mostrar todas'
    }
  }

  showAllnewsNotices() {
    this.NewNoticeTargets.forEach((element) => {
      element.classList.remove('hidden')
    });
  }

  readMore(event){
    this.element.querySelector('[data-container-news="'+event.target.dataset.idNews+'"]').classList.toggle('in')
    event.target.innerText == "Leer menos" ? event.target.innerText = "Leer más" : event.target.innerText = "Leer menos"
  }

  showButtonMore(){
    this.NewNoticeTargets.forEach((item) => {
      item.querySelector('.trix-content').clientHeight >= 100 ? item.querySelector('.btn-show-news').classList.remove('hidden') : item.querySelector('.btn-show-news').classList.add('hidden')
    })
  }

  resume() {
    this.travelChart()
    this.paymentChart()
  }

  tutoriales() {
    this.video = new Plyr('#tutorial_video')
  }

  modalVideo(event) {
    let id = event.target.dataset.id
    let poster = event.target.dataset.poster
    let urlVideo = event.target.dataset.urlvideo
    this.video.source = {
      type: 'video',
      sources: [
        {
          src: urlVideo,
        }
      ],
      poster: poster,
    }
    MicroModal.show(id)
  }

  stopVideo() {
    this.video.stop()
  }

  travelChart() {
    const ctx1 = document.getElementById('travelChart');
    const myChart1 = new Chart(ctx1, {
      type: 'pie',
      data: {
        labels: ['Red', 'Orange'],
        datasets: [
          {
            label: "Issues",
            data: [1, 2],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)'
            ],
          }
        ]
      }
    })

  }

  paymentChart() {
    const ctx = document.getElementById('paymentChart');
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
          label: 'My First Dataset',
          data: [12, 19, 3, 5, 5, 3],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      }
    })
  }
}