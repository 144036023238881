import { Controller } from 'stimulus';
import Swal from 'sweetalert2'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['reportId', 'title', 'text']

  connect(){
    console.log("async report connected")
    this.done = false
    this.openStartModal()
  }

  openStartModal(){
    Swal.fire({
      title: this.titleTarget.value,
      text: this.textTarget.value,
      icon: 'info',
      showConfirmButton: false,
      showCancelButton: false,
    })

    this.queryStatus()
  }

  triggerQueryStatus(){
    console.log(this.done)
    if(this.done){
      Swal.fire({
        icon: 'success',
        title: 'Descargar documentos',
        text: 'Tus documentos están listos para ser descargados.',
        showCancelButton: false,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(this.url)
        }
      })
    } else {
      setTimeout( () => {
      this.queryStatus()
      }, 3000)
    }
  }

  queryStatus(){
    let id = this.reportIdTarget.value
    Rails.ajax({
      url: '/api/async_reports/' + id,
      type: 'GET',
      success: (response) => {
        if(response.done) {
          this.done = true
          this.url = response.url
        }
        this.triggerQueryStatus()
      },
      error: (response) => {
        Swal.fire({
          title: 'Ocurrió un error',
          icon: 'error'
        })
        console.log(response)
      }
    })
  }
}
