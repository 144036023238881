import { Controller } from 'stimulus';
import introJs from "intro.js"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    view: String,
    page: String
  }

  connect(data) {
    this.wizard = null
    this.introjs = introJs()
    const url = `/api/wizards`
    if (data) {
      this.wizard = data.wizard
      this.initializeWizard()
    } else {
      fetch(url)
        .then(response => response.json())
        .then((data) => {
          this.wizard = data.wizard
          this.initializeWizard()
        })
    }
  }

  getData() {
    const url = `/wizard/${this.viewValue}.json`
    const data = fetch(url).then(response => response.json())
    return data
  }

  checkStatus() {
    let complete = this.wizard[this.viewValue] === true
    return complete
  }

  updateSatatus() {
    var formData = new FormData();
    formData.append(`wizard[${this.viewValue}]`, true);

    Rails.ajax({
      url: '/api/wizards',
      type: 'POST',
      data: formData,
      success: (data) => {
        console.log('wizard updated', data)
      },
      error: (data) => {
        console.log('error updating wizard', data)
      }
    })
  }

  async initializeWizard() {
    if (!this.checkStatus()) {
      const data = await this.getData()
      const _this = this
      this.introjs.setOptions({
        showProgress: true,
        exitOnOverlayClick: false,
        exitOnEsc: false,
        showBullets: false,
        disableInteraction: this.pageValue == 'index' ? true : false,
        nextLabel: 'Siguiente',
        prevLabel: 'Atrás',
        doneLabel: this.pageValue == 'index' ? 'Siguiente' : 'Terminar',
        steps: data[this.pageValue]
      });


      this.introjs.onbeforechange(function (targetElement) {
        let visible = targetElement != undefined ? _this.visible(targetElement) : true
        if (!visible) {
          setTimeout(() => {
            if (this._direction == 'forward') {
              _this.introjs.goToStep(this._currentStep + 2)
            }
            if (this._direction == 'backward') {
              _this.introjs.previousStep();
            }
          })
        }
      })

      this.introjs.oncomplete(function () {
        if (_this.pageValue == 'index') {
          let btn = document.querySelector('.btn-primary') != null ? document.querySelector('.btn-primary') : document.querySelector('.btn-edit')
          btn.click()
        } else {
          _this.updateSatatus()
        }
      }).start();
    }
  }

  invisibleElements(data) {
    const elements = data.filter(item => !this.visible(item.element))
    return elements
  }
  visible(e) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
  }

}
