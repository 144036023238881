import { Controller } from "stimulus"
import MicroModal from "micromodal"


export default class extends Controller {
  static targets = [
    'additionalTrailerContainer', 'origin', 'destination', 'routeType',
    'vehicle', 'trailerToggleContainer', 'trailer', 'additionalTrailer',
    'routeContainer', 'local_trip'
  ]

  connect(){
    console.log('assignment controller')

  }

  updateAdditionalTrailer(e) {
    let show = e.currentTarget.checked
    if(show){
      this.additionalTrailerContainerTarget.classList.remove('hidden')
    } else {
      this.additionalTrailerContainerTarget.classList.add('hidden')
    }
  }

  updateRoute(e){
    let object = e.detail.object
    let keys = Object.keys(object.route_type)

    console.log(object.route_type, keys[0])
    this.routeTypeTarget.innerHTML = object.route_type[keys[0]]

    let stops = object.stops_names.split('|')
    this.routeContainerTarget.innerHTML = ''

    let i = 0
    for(const stop of stops){
      this.routeContainerTarget.innerHTML += this.stopLabelFor(stop)

      if(i < stops.length - 1) this.routeContainerTarget.innerHTML += this.arrowVector();
      i++
    }
  }

  updateTrailerOptions(e){
    const option = this.vehicleTarget.options[this.vehicleTarget.selectedIndex]
    const vehicleType = option.dataset.vehicleSubType

    if(vehicleType.includes('tract')){
      this.updateOptionsState(this.trailerTarget, 'full_trailer')
      this.updateOptionsState(this.additionalTrailerTarget, null)
      this.trailerToggleContainerTarget.classList.remove('hidden')
    } else {
      this.updateOptionsState(this.trailerTarget, 'semi_trailer')
      this.updateOptionsState(this.additionalTrailerTarget, null)

      const check = this.trailerToggleContainerTarget.querySelector('input')
      check.checked = false
      this.updateAdditionalTrailer({ currentTarget: check })
      this.trailerToggleContainerTarget.classList.add('hidden')
    }
  }

  updateOptionsState(select, target){
    select.value = ''
    for(const option of select.querySelectorAll('option')){
      option.disabled = target !== null && option.dataset.trailerCategory === target
    }
  }

  stopLabelFor(name) {
    return `<div class='flex items-center justify-center bg-naviioGreen px-2'><b>${name}</b></div>`
  }

  arrowVector(){
    return `
      <div class='flex items-center justify-center px-2 sm:px-6'>
        <svg class='h-5 w-5' fill='currentColor' viewbox='0 0 20 20' xlms='http://www.w3.org/2000/svg'>
          <path clip-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" fill-rule="evenodd"></path>
        </svg>
      </div>
    `
  }

  newTrailer(e){
    let type = e.currentTarget.dataset.type
    console.log(type)
    document.getElementById('trailerModalType').value = type
    MicroModal.show('trailerModal')

    // let modal = document.getElementById('trailerFormContainer')
    // for(const input of modal.querySelectorAll('input')){ input.value = '' }
    // for(const input of modal.querySelectorAll('select')){ input.value = '' }
  }

  trailerCreated(e){
    let object = e.detail.object
    let type = e.detail.type

    console.log(object, type)

    let target = type == 'trailer' ? this.trailerTarget : this.additionalTrailerTarget

    let opt = document.createElement('option');
    opt.value = object.id;
    opt.innerHTML = `${object.economic_number} - ${object.trailer_category_i18n}`;
    opt.dataset.trailerCategory = object.trailer_category

    target.appendChild(opt);

    MicroModal.close('trailerModal')


    console.log(this.vehicleTarget.value)
    if(this.vehicleTarget.value == '') {
      target.value = object.id
      return
    }

    this.updateTrailerOptions()
    if(!opt.disabled) target.value = object.id
  }
}
