import { Controller } from "stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static targets = ['select']

  connect() {
    this.initSelect()
  }

  initSelect() {
    this.select = new SlimSelect({
      select: this.selectTarget,
      text: this.selectTarget.dataset.textMethod,
      value: this.selectTarget.dataset.valueMethod,
      searchingText: 'Buscando...',
      searchText: 'No hay registros',
      searchPlaceholder: 'Buscar'
    })
  }
}
