import { Controller } from "stimulus"
import flatpickr from 'flatpickr/dist/flatpickr.min.js'
import { Spanish } from "flatpickr/dist/l10n/es.js"
import 'flatpickr/dist/flatpickr.css'

export default class extends Controller {
  static targets = ['inputDate']

  connect() {
    this.initializePickr(this.inputDateTarget)
  }
  initializePickr(targ){
    let format = targ.dataset.format || 'd-m-Y'
    let enableTime = targ.dataset.enableTime || false
    console.log(format)
    flatpickr(targ, {
      "locale": Spanish,
      disableMobile: 'true',
      enableTime: enableTime,
      dateFormat: format,
    })
  }
}
