import { Controller } from "stimulus"
import SlimSelect from "slim-select"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'addBtn', 'product', 'unit', 'satProduct', 'taxBase', 'quantity',
    'unitPrice', 'discount', 'total', 'tax'
  ]

  connect() {
    this.addBtnTarget.setAttribute('data-association-insertion-node', function(e){
      const element = e.closest('.concept')
      return element.querySelector('.taxes')
    })

    this.productSelect = new SlimSelect({
      select: this.productTarget,
      showSearch: false,
      onChange: (info) => {
        console.log(info)
        this.autoFillFromProduct(info)
      }
    })
    this.initSatSelects()
  }

  taxAdded(e){
    console.log(e)
  }

  taxRemoved(e){
    let removed = e.detail[0]

    removed.querySelector('.feeAmount').dataset.removed = true
    this.triggerGlobalCalculation()
  }

  calculate(){
    let quantity = parseFloat(this.quantityTarget.value) || 0
    let price = parseFloat(this.unitPriceTarget.value) || 0
    let discount = parseFloat(this.discountTarget.value) || 0

    let total = (quantity * 100) * (price * 100)
    if(isNaN(total)) total = 0;

    total = (total / 10000) - discount
    this.totalTarget.value = total.toFixed(2)

    const event = new Event('change');
    this.totalTarget.dispatchEvent(event);

    this.triggerGlobalCalculation()
  }

  updateTotal(e){
    console.log('update total')
    const value = e.currentTarget.value

    for(const tax of this.taxBaseTargets){
      tax.value = value
      const event = new Event('change');
      tax.dispatchEvent(event);
    }
  }

  autoFillFromProduct(obj){
    let data = obj.data
    let unitData = [{ text: `${data.satUnitCode} ${data.satUnitName}`, value: data.satUnitCode, selected: true  }]
    this.unitSelect.setData(unitData)
    this.unitSelect.set(data.satUnitCode)

    let productData = [{ text: `${data.satProductCode} ${data.satProductName}`, value: data.satProductCode, selected: true  }]
    this.satProductSelect.setData(productData)
    this.satProductSelect.set(data.satProductCode)

    let taxes = JSON.parse(data.taxes)
    this.fillTaxes(taxes)
  }

  fillTaxes(taxes){
    let i = 0
    for(const tax of taxes){
      let element = this.taxTargets[i]
      if(element !== null){
        console.log('exists', i)
        this.selectTax(element, tax)
      } else {
        console.log('adding')
        this.element.querySelector('.add').click()
        setTimeout( () => {
          element = this.taxTargets[i]
          this.selectTax(element, tax)
        }, 300)
      }
      i++;
    }
  }

  selectTax(element, obj){
    let value = `${obj.sat_tax_name}|${obj.sat_tax_type}`
    const taxSelect = element.querySelector('.taxSelect')
    console.log(taxSelect, value)

    taxSelect.value = value
    const event = new Event('change');
    taxSelect.dispatchEvent(event);
  }

  initSatSelects(){
    let url = '/api/sat?catalog=measure_units'
    this.unitSelect = this.initializeRemoteSearch(this.unitTarget, url)
    url = '/api/sat?catalog=products'
    this.satProductSelect = this.initializeRemoteSearch(this.satProductTarget, url)
  }

  triggerGlobalCalculation(){
    const event = new CustomEvent('calculate',{
      bubbles: true
    })
    this.element.dispatchEvent(event)
    console.log('dispatched global event')
  }

  initializeRemoteSearch(select, url, onChange){
    return new SlimSelect({
      select: select, 
      text: 'description', 
      value: 'code', 
      searchingText: 'Buscando...',
      searchText: 'No hay registros',
      searchPlaceholder: 'Buscar',
      onChange: onChange,
      ajax: function (search, callback) {
        if (search.length < 2) {
          callback('Al menos 2 caracteres')
          return
        }

        Rails.ajax({
          url: url,
          type: 'GET',
          data: `q=${search}`,
          success: function(data){
            let result = []
            for(const obj of data) {
              let row = {}
              console.log(obj)
              row.text = `${obj.code} ${obj.description}`
              row.value = obj.code
              row.data = obj
              result.push(row)
            }
            callback(result)
          },
          error: function(error){
            callback(false)
          }
        })
      }
    })
  }
}
