import { Controller } from "stimulus"
import MicroModal from "micromodal"

export default class extends Controller {
  static targets = [
    'complementModalBtn', 'sendMailBtn'
  ]

  connect(){
    MicroModal.init()
    console.log('connect payments show')
  }

  hideComplementModal(){
    MicroModal.close('complementModal')
    setTimeout( () => {
      MicroModal.show('sendModal')
    }, 200)
  }

  hideSendMailModal(){
    MicroModal.close('sendModal')
    setTimeout( () => {
      MicroModal.show('complementModal')
    }, 200)
  }
}
