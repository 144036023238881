import { Controller } from 'stimulus';
export default class extends Controller {

  static targets = ['route', 'btnDelRoute']

  connect() {
    this.countRoutes();
  }

  afterInsert(e) {
    this.countRoutes();
  }

  afterRemove(e) {
    this.countRoutes();
  }
  countRoutes() {
    let cont = 0;
    let contElements = this.routeTargets.length

    this.routeTargets.forEach((item) => {
      if (item.style.display) {
        cont++;
      }
    })
    contElements = contElements - cont
    if (contElements == 1) {
      this.btnDelRouteTargets.forEach((element) => {
        element.style.display = 'none'
      });
    }
    if (contElements == 2) {
      this.btnDelRouteTargets.forEach((element) => {
        element.style.display = 'block'
      });
    }
  }
}
