import Wizard from './wizard_controller.js'
import MicroModal from "micromodal"
export default class extends Wizard {
  static targets = ['routeType']
  connect() {
    const url = `/api/wizards`
    fetch(url)
      .then(response => response.json())
      .then((data) => {
        if (this.catalogStatus(data)) {
          setTimeout(() => {
            super.connect(data)
            if (this.pageValue != 'index') { this.stepChanges() }
          },300)
        }
      })
  }

  catalogStatus(data) {
    const { shipping_contracts, locations, businesses, clients, employees, vehicles } = data.wizard
    const catalog = [shipping_contracts, locations, businesses, clients, employees, vehicles]
    let status = catalog.filter(item => item != true)
    return status.length === 0 ? true : false
  }

  nextStepCloseModal() {
    this.introjs.goToStep(4)
  }

  stepChanges() {
    const _this = this

    this.introjs.onafterchange(function(targetElement) {
      if (this._currentStep == 0){
        let btnNext = document.querySelector('.introjs-nextbutton')
        let clientSelect = document.querySelector('#client_selection')
        clientSelect.value != '' ? btnNext.classList.remove('introjs-disabled', 'pointer-events-none') : btnNext.classList.add('introjs-disabled', 'pointer-events-none')
      }
    })

    this.introjs.onchange(function (targetElement) {
      if (this._currentStep == 0 || this._currentStep == 3) {
        _this.pointerStateIntro()
      }

      if (this._currentStep == 2) {
        if (this._direction == 'backward') {
          MicroModal.show('routeModal')
          setTimeout(() => {
            _this.introjs.goToStep(1)
          }, 300)
        }
        setTimeout(() => {
          let element = document.querySelector('.introjs-nextbutton')
          element.classList.add('introjs-disabled', 'pointer-events-none')
        })
      }

      if (this._currentStep == 15 && _this.routeTypeTarget.textContent == 'exportación') {
        if (this._direction == 'forward') {
          setTimeout(() => {
            _this.introjs.goToStep(17)
          })
        }
        if (this._direction == 'backward') {
          setTimeout(() => {
            _this.introjs.goToStep(15)
          })
        }
      }
    });
  }

  pointerStateIntro() {
    setTimeout(() => {
      let introClass = document.querySelector('.introjs-helperLayer')
      let overlaYClass = document.querySelector('.introjs-overlay')
      let modal = document.querySelector('#routeModal')
      modal.querySelector('.modal__overlay').removeAttribute("data-micromodal-close")
      modal.querySelector('.modal__close').removeAttribute("data-micromodal-close")
      modal.querySelector('.btn-edit').disabled = true
      if (modal.classList.contains('is-open')) {
        introClass.style.pointerEvents = "none"
        overlaYClass.style.pointerEvents = "none"
      } else {
        introClass.style.pointerEvents = "auto"
        overlaYClass.style.pointerEvents = "auto"
      }
    })

  }
}