import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['submenu']

  connect() {
    this.init();
  }

  init() {
    let sidebar = document.querySelector(".sidebar");
    let closeBtn = document.querySelector("#btn");

    closeBtn.addEventListener("click", () => {
      sidebar.classList.toggle("open");
    });
  }

  open_submenu(event) {
    event.target.classList.add('show-menu')
  }

  hide_submenus(event) {
    let item = event.target.classList.contains("item-menu");
    let subMenus = document.querySelectorAll("li.show-menu");
    if (!item) {
      subMenus.forEach((item) => {
        item.classList.remove('show-menu')
      })
    }

  }
}