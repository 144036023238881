import { Controller } from 'stimulus'; 
export default class extends Controller {

  static targets = ['journey']

  connect() {
    this.updateStops()
  }

  afterInsert(e){
    this.updateStops()
  }

  afterRemove(e) {
    this.updateStops()
  }

  updateStops(){
    let i = 0
    for(const journeyContainer of this.journeyTargets){
      let previousContainer = i == 0 ? null : this.journeyTargets[i - 1]
      this.toggleOriginValue(journeyContainer, previousContainer, i)
      i++;
    }
  }

  toggleOriginValue(container, previousContainer, index) {
    if(index === 0){
      for(const input of container.querySelectorAll('input')){
        input.readOnly = false
      }
      for(const input of container.querySelectorAll('select')){
        input.classList.remove('pointer-events-none')
      }
      return;
    }

    if(previousContainer == null) return;

    let values = this.getDestinationValues(previousContainer)
    this.updateOrigin(container, values)
  }

  getDestinationValues(container) {
    return {
      location_id: container.querySelector('.destination-input').value,
      tin: container.querySelector('.destination-tin').value,
      country: container.querySelector('.destination-country').value,
      business: container.querySelector('.destination-business').value
    }
  }

  updateOrigin(container, values) {

    let country =  container.querySelector('.origin-country')
    country.value = values.country
    country.classList.add('pointer-events-none')

    let tin =  container.querySelector('.origin-tin')
    tin.value = values.tin
    tin.readOnly = true

    let location =  container.querySelector('.origin-input')
    location.value = values.location_id
    location.classList.add('pointer-events-none')

    let business =  container.querySelector('.origin-business')
    business.value = values.business
    business.classList.add('pointer-events-none')
  }
}
