import { Controller } from 'stimulus'; 
export default class extends Controller {
  static values = { with: String }
  static targets = ['btn']

  connect() {
    this.element.dataset['action'] = 'submit->disable#disableForm'

    console.log('connected disable!')
    console.log('connected disable!: Found ', this.submitButtons().length)
    if (!this.hasWithValue) {
      this.withValue = "GUARDANDO..."
    }
  }

  disableForm() {
    console.log('disabling!')
    console.log('submit btns: ', this.submitButtons.length)
    this.submitButtons().forEach(button => {
      button.disabled = true
      button.value = this.withValue
    })
  }

  submitButtons() {
    if(this.btnTargets.length > 0){
      return this.btnTargets
    } else {
      return this.element.querySelectorAll("input[type='submit']")
    }
  }
}
