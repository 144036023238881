import { Controller } from 'stimulus';
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'zipCode', 'neighborhood', 'location', 'city', 'state', 'country',
    'mxAddress', 'usAddress'
  ]

  connect(){
    this.debouncer = null;
    if(!this.hasCountryTarget) return
    this.countryChanged()
  }

  countryChanged(){
    let value = this.countryTarget.value
    if(value === 'MX' || value === 'mex'){
      this.mxAddressTarget.classList.remove('hidden')
      this.usAddressTarget.classList.add('hidden')
      this.disableInputs(this.usAddressTarget, true)
      this.disableInputs(this.mxAddressTarget, false)
    } else {
      this.mxAddressTarget.classList.add('hidden')
      this.usAddressTarget.classList.remove('hidden')
      this.disableInputs(this.usAddressTarget, false)
      this.disableInputs(this.mxAddressTarget, true)
      this.neighborhoodTarget.innerHTML = ''
    }

    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('change', true, true, {})
    this.zipCodeTarget.dispatchEvent(event)
  }

  disableInputs(container, state){
    for(const input of container.querySelectorAll('input')){
      input.disabled = state
    }

    for(const input of container.querySelectorAll('select')){
      input.disabled = state
    }
  }

  zipCodeChanged(){
    if(!['MX', 'mex'].includes(this.countryTarget.value)) return;

    if(this.debouncer !== null) clearTimeout(this.debouncer)
    this.debouncer = setTimeout( () => { this.loadAddress() }, 250)
  }

  loadAddress(){
    let value = this.zipCodeTarget.value
    if (value.length < 5) {
      console.log('not lenght enough', value.length)
      return
    }
    Rails.ajax({
      url: '/api/sat_addresses',
      type: 'GET',
      data: `zip_code=${value}`,
      success: function(data){
        let address = data.address
        this.paintAddress(address)
        if(this.hasNeighborhoodTarget){
          if(address !== null) {
            this.drawNeighborhoodOptions(address.neighborhoods)
          } else {
            this.drawNeighborhoodOptions([])
          }
        }
      }.bind(this),
      error: function(error){
        this.paintAddress({})
        if(this.hasNeighborhoodTarget) this.drawNeighborhoodOptions([])
      }.bind(this)
    })
  }

  drawNeighborhoodOptions(options){
    this.neighborhoodTarget.innerHTML = ''
    for(const object of options){
      var opt = document.createElement('option');
      opt.value = object.code;
      opt.innerHTML = object.name;
      this.neighborhoodTarget.appendChild(opt);
    }
  }

  paintAddress(address){

    if (this.hasLocationTarget){
      if(address != null && address.location !== null){
        this.locationTarget.querySelector('input').value = address.location.code
        this.locationTarget.querySelector('span.value').innerHTML = address.location.name
      } else {
        this.locationTarget.querySelector('input').value = null
        this.locationTarget.querySelector('span.value').innerHTML = ''
      }
    }

    if(this.hasCityTarget){
      if(address != null && address.city !== null){
        this.cityTarget.querySelector('input').value = address.city.code
        this.cityTarget.querySelector('span.value').innerHTML = address.city.name
      } else {
        this.cityTarget.querySelector('input').value = null
        this.cityTarget.querySelector('span.value').innerHTML = ''
      }
    }

    if(this.hasStateTarget){
      if(address != null && address.state !== null){
        this.stateTarget.querySelector('input').value = address.state.code
        this.stateTarget.querySelector('span.value').innerHTML = address.state.name
      } else {
        this.stateTarget.querySelector('input').value = null
        this.stateTarget.querySelector('span.value').innerHTML = ''
      }
    }
  }
}
