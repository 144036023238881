import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['subMenu','btnOpen','btnClose']

  connect() {
  
  }

  openSubmenu() {
    this.subMenuTarget.classList.toggle('hidden')
  }

  openCloseSidebar(){
    let isSidebar = document.querySelector('.is-sidebar')
    let body = document.querySelector('body')

    this.btnOpenTarget.classList.toggle('hidden')
    this.btnCloseTarget.classList.toggle('hidden')
    this.element.classList.toggle('translate-x')
    isSidebar.classList.toggle('hidden')
    body.classList.toggle('overflow-y-hidden')
  }

}