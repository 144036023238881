import { Controller } from 'stimulus';
import Glide from '@glidejs/glide';

export default class extends Controller {
  static targets = ["glide", "forwardButton", "prevButton"]

  connect() {
    this._glider = undefined

    if (!this.glideTarget.querySelector('.glide__slides').hasChildNodes()) {
      this.glideTarget.querySelector('.glide__arrows').classList.add('hidden')
      return
    }
    this.initGlide()
  }

  initGlide() {
    this._glider = new Glide(this.glideTarget, {
      type: 'slider',
      perView: 5,
      startAt: 0,
      bound: true,
      rewind: false,
      breakpoints: {
        2000: {
          perView: 4
        },
        1500: {
          perView: 3
        },
        600: {
          perView: 1
        }
      }
    })
    this._glider.mount()
    this._glider.on('run', () => {
      this.hideForwardButton()
      this.hidePrevButton()
    })
    this.prevButtonTarget.style.visibility = 'hidden'
  }

  hidePrevButton() {
    if (this._glider._c.Run.isStart() == true) {
      this.prevButtonTarget.style.visibility = 'hidden'
    } else {
      this.prevButtonTarget.style.visibility = ''
    }
  }

  hideForwardButton() {
    if (this._glider._c.Run.isEnd() == true) {
      this.forwardButtonTarget.style.visibility = 'hidden'
    } else {
      this.forwardButtonTarget.style.visibility = ''
    }
  }
}