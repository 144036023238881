import { Controller } from "stimulus"
import SlimSelect from "slim-select"
export default class extends Controller {
  static targets = [
    'client', 'cfdiUsage', 'paymentMethod', 'paymentMean', 'currency',
    'exchangeRate', 'exchangeRateContainer', 'subtotalLabel', 'discountLabel',
    'transferredTaxesLabel', 'retainedTaxesLabel', 'totalLabel',
    'conceptTotal', 'conceptTax', 'conceptDiscount', 'foreignField',
    'country', 'tin', 'concept', 'receiverZipCode', 'receiverTaxRegime'
  ]

  connect() {
    this.clientSelect = new SlimSelect({
      select: this.clientTarget,
      onChange: (info) => {
        console.log(info)
        this.fillClientAttributes(info)
      }
    })
    this.updateExchangeRate()
    this.calculate()
    this.formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  updatePaymentMean(){
    let value = this.paymentMethodTarget.value
    if(value === 'PPD'){
      this.paymentMeanTarget.value = '99'
    } else {
      this.paymentMeanTarget.value = null
    }
  }

  fillClientAttributes(object) {
    console.log(object.data)
    if(object !== null) {
      this.cfdiUsageTarget.value = object.data.cfdi_usage
      this.paymentMethodTarget.value = object.data.payment_method
      this.currencyTarget.value = object.data.currency
      this.receiverZipCodeTarget.value = object.data.zipCode
      this.receiverTaxRegimeTarget.value = object.data.taxRegime
    } else {
      this.cfdiUsageTarget.value = null
      this.paymentMethodTarget.value = null 
      this.currencyTarget.value = 'MXN' 
    }

    this.updatePaymentMean()

    for(const elem of this.foreignFieldTargets){
      if(object.data.foreign == 'true'){
        elem.classList.remove('hidden')
      } else {
        elem.classList.add('hidden')
      }
    }
    this.updateExchangeRate()
  }

  updateExchangeRate(){
    console.log(this.currencyTarget.value)
    this.exchangeRateTarget.value = ''
    if(this.currencyTarget.value == 'MXN') {
      this.exchangeRateTarget.disabled = true
      this.exchangeRateContainerTarget.classList.add('hidden')
    } else {
      this.exchangeRateTarget.disabled = false
      this.exchangeRateContainerTarget.classList.remove('hidden')
    }
  }

  conceptRemoved(e){
    let removed = e.detail[0]

    removed.querySelector('.base').dataset.removed = true
    removed.querySelector('.discount').dataset.removed = true
    const taxes = removed.querySelectorAll('.feeAmount')
    for(const tax of taxes){
      tax.dataset.removed = true
    }

    this.calculate()
    this.conceptAdded()
  }

  conceptAdded(){
    let i = 0;
    for(const container of this.conceptTargets){
      let removeBtn = container.querySelector('.remove_concept')
      if(i == 0){
        removeBtn.classList.add('hidden')
      } else {
        removeBtn.classList.remove('hidden')
      }
      i++;
    }
  }

  calculate(){
    console.log('processing global event')
    if(this.calculateTimeout !== null) clearTimeout(this.calculateTimeout)

    this.calculateTimeout = setTimeout( () => {
      this.performCalculation()
    }, 600)
  }

  performCalculation(){
    let concepts = this.sum(this.conceptTotalTargets)
    let discounts = this.sum(this.conceptDiscountTargets)
    let transfers = []
    let retains = [] 


    for(const elem of this.conceptTaxTargets){
      if(elem.dataset.removed) continue;

      console.log(elem.dataset.taxType)
      if(elem.dataset.taxType === 'transfer'){
        transfers.push(elem)
      } else if(elem.dataset.taxType === 'retain'){
        retains.push(elem)
      }
    }

    transfers = this.sum(transfers)
    retains = this.sum(retains)

    console.log('concepts', concepts)
    console.log('discounts', discounts)
    console.log('transfers', transfers)
    console.log('retains', retains)

    this.subtotalLabelTarget.innerHTML = this.formatter.format(concepts + discounts)
    this.discountLabelTarget.innerHTML = this.formatter.format(discounts)
    this.transferredTaxesLabelTarget.innerHTML = this.formatter.format(transfers)
    this.retainedTaxesLabelTarget.innerHTML = this.formatter.format(retains)

    let total = concepts + transfers - retains
    this.totalLabelTarget.innerHTML = this.formatter.format(total) 

  }

  sum(collection){
    if(collection === undefined) return 0;

    let sum = 0
    for(const element of collection){
      if(element.dataset.removed) continue;

      let val = (parseFloat(element.value) || 0) * 100
      sum += val
    }

    return (sum / 100)
  }

  cancelFieldEvents(e){
    return (e.key || 0) !== 'Enter' ? true :  e.target.blur()
  }
}
