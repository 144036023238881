import Wizard from './wizard_controller.js'
export default class extends Wizard {

  connect() {
    this.visibleElement()
    super.connect()
  }

  visibleElement() {
    if (this.pageValue == 'index') return
    let element = this.element.querySelector('#wizard_product')
    this.pageValue = element ? this.pageValue : 'new-transport'
  }
}