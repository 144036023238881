import { Controller } from 'stimulus';
export default class extends Controller {

  connect() {
    this.eventSnippet()
    this.hiddenElements()
    this.redirect()
  }

  redirect() {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000)
  }

  hiddenElements() {
    document.querySelector('.sidebar').style.display = 'none'
    document.querySelector('.sidebar-mobile').style.display = 'none'
    document.querySelector('nav').classList.add('hidden')
  }

  eventSnippet(){
    gtag('event', 'conversion', {'send_to': 'AW-959546420/zEDVCOqIudACELSIxskD'})
  }

}