import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [
    'shipment', 'routeToggable', 'materialToggable', 'addBtn'
  ]

  connect(){
    console.log('connected shipment')
    this.currentMaterialType = null
    this.currentRouteType = null
  }

  onSelectChange(e){
    let event = e.detail
    let name = event.object.name
    let info = event.info
    if(name.includes('product_key')){
      let shipment = event.object.closest('.shipment')
      let toggable = shipment.querySelector('.hazardous')
      let value = info.data.hazardous == '1' ? 'hazardous' : 'normal'
      this.toggleVisibility([toggable], value)
    }
  }

  updateRoute(e){
    console.log('updating route')
    let object = e.detail.object
    let materialType = object.shipping_contract_material_type

    let routeType = Object.keys(object.route_type)[0]
    console.log(materialType, routeType)

    this.currentMaterialType = materialType
    this.currentRouteType = routeType
    this.updateFields()
  }

  updateFields(){
    // if(this.currentMaterialType !== null){
    //   this.toggleVisibility(this.materialToggableTargets, this.currentMaterialType)
    // }
    if(this.currentRouteType !== null){
      this.toggleVisibility(this.routeToggableTargets, this.currentRouteType)
    }
  }

  toggleVisibility(elements, targetValue){
    for(const el of elements){
      let val = el.dataset.value.split(',')
      console.log(val, targetValue)
      if(val.includes(targetValue)){
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    }
  }

  addShipment(){
    this.addBtnTarget.click()
  }

  updateShipments(e) {
    console.log('update shipments')
    this.updateFields()
    let i = 0;
    for(const row of this.shipmentTargets){
      if(i == 0){
        row.querySelector('.add').classList.remove('hidden')
        row.querySelector('.remove').classList.add('hidden')
      } else {
        row.querySelector('.add').classList.add('hidden')
        row.querySelector('.remove').classList.remove('hidden')
      }
      i++;
    }
  }
}

