import { Controller } from "stimulus"
import MicroModal from "micromodal"
import Rails from "@rails/ujs"


export default class extends Controller {
  static targets = [
    'clientSelection', 'routeSearch', 'resultsContainer', 'resultsTable',
    'freightPathContainer', 'routeId'
  ]

  connect() {
    MicroModal.init()
    console.log(this.routeIdTarget.value)
    if(this.routeIdTarget.value === '') MicroModal.show('routeModal');
    if(this.clientSelectionTarget.value == ''){
      this.routeSearchTarget.disabled = true
    }
    setTimeout(() => {
      this.clientSelectionTarget.focus()
    }, 500)

    this.searchTimeout = null
  }

  clientSelected(){
    const btnNext = document.querySelector('.introjs-nextbutton')
    this.routeSearchTarget.disabled = this.clientSelectionTarget.value == ''

    if(this.clientSelectionTarget.value != ''){
      btnNext != null ? btnNext.classList.remove('introjs-disabled', 'pointer-events-none') : ''
      this.routeSearchTarget.focus()
    }else {
      btnNext != null ? btnNext.classList.add('introjs-disabled', 'pointer-events-none'): ''
    }
    this.triggerSearch()
  }

  triggerSearch(){
    if(this.searchTimeout != null) clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout( () => {
      this.search()
    }, 500)
  }

  search(){
    let client = this.clientSelectionTarget.value
    let query = this.routeSearchTarget.value
    console.log(client, query)
    Rails.ajax({
      url: '/api/routes',
      type: 'GET',
      data: `client_id=${client}&q=${query}`,
      success: (data) => {
        this.showResults(data)
      }
    })
  }

  showResults(data) {
    this.resultsTableTarget.innerHTML = ''
    this.resultsContainerTarget.classList.remove('hidden')
    for(const row of data){
      this.resultsTableTarget.innerHTML += this.getRouteHTML(row)
    }
  }

  getRouteHTML(obj){
    return `
      <tr class='bg-gray-100 text-center border-b text-sm text-gray-600'>
      <td class='p-2 border-r'>${obj.shipping_contract_folio}</td>
      <td class='p-2 border-r'>${obj.shipping_contract_material_type_i18n}</td>
      <td class='p-2 border-r'>${obj.shipping_contract_comments}</td>
      <td class='p-2 border-r flex flex-row'>${this.getStopLabels(obj.stops_names)}</td>
      <td class='p-2 border-r'><input type='button' data-object='${JSON.stringify(obj)}' value='SELECCIONAR' data-action='click->freights--route#selectRoute click->wizard-freights#nextStepCloseModal' class='bg-naviioBlue text-white px-6 py-2 rounded font-medium mx-3 hover:bg-naviioBlue cursor-pointer'/></td>
      </tr>
    `;
  }

  getStopLabels(stopsStr){
    let stops = stopsStr.split('|')

    return stops.map(function(s){
      return `<label class='flex items-center text-white justify-center bg-naviioGreen px-2 mx-1 rounded uppercase'>${s}</label>`
    }).join('&#8594;')
  }

  selectRoute(e){
    let row = e.currentTarget
    let object = JSON.parse(row.dataset.object)
    object.client_id = this.clientSelectionTarget.value
    // let clientOption = this.clientSelectionTarget.querySelectorAll('option').find(function(opt) { return option.selected })
    // object.client_name = clientOption.innerHTML
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('routeSelected', true, true, {
      object: object
    })
    window.dispatchEvent(event)
    MicroModal.close('routeModal');
    this.freightPathContainerTarget.classList.remove('hidden')
  }

  openTemporaryRouteModal(){
    MicroModal.close('routeModal')
    setTimeout(() => {
      MicroModal.show('temporaryRouteModal')
    }, 200)
  }

  closeTemporaryRouteModal(){
    MicroModal.close('temporaryRouteModal')
    this.freightPathContainerTarget.classList.remove('hidden')
  }

  cancelFieldEvents(e){
    return (e.key || 0) !== 'Enter' ? true :  e.target.blur()
  }
}
