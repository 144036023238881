import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['control', 'toggable']
  static values = { mode: String, klass: String }

  connect(){
    console.log('visible')
  }

  toggle(){
    var show = this.getValue()
    for(const element of this.toggableTargets){
      this.updateVisibility(element, show)
    }
  }

  updateVisibility(element, show){
    if(this.modeValue == 'css') {
      element.classList.toggle(this.klassValue)
    }
  }

  getValue() {
    if(this.controlTarget.type == 'checkbox') {
      return this.controlTarget.checked
    } else {
      return null;
    }
  }

}
