import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['operatorFields', 'MEXAddressFields', 'USAAddressFields', 'nationalityField']
  
  toggleOperatorFields(e){
    if (e.currentTarget.value == 'operator') {
      this.operatorFieldsTarget.classList.remove('hidden')
    }else{
      this.operatorFieldsTarget.classList.add('hidden')
    }
  }
}
