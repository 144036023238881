import { Controller } from 'stimulus';
import Rails from "@rails/ujs"
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = [
    'localTripModal', 'localCheck', 'submitButton'
  ]

  connect(){
    console.log('generalForm shipping_contract')
  }

  showLocalTripModal(){
    if(this.localCheckTarget.checked){
      this.modalAlert()
    }
    else{
      this.clickSubmitButton();
    }
  }

  modalAlert(){
    Swal.fire({
      title: 'IMPORTANTE',
      text: "Has marcado el convenio con un 'viaje local', al crear un viaje con este convenio no se generará el complemento de carta porte. ¿Estás seguro de que este es un viaje local o que no saldrá a jurisdicción federal?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#6a727f',
      confirmButtonColor: '#00aba0',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'No, regresar'
    }).then((result) => {
      if(result.isConfirmed){
        this.clickSubmitButton()
      }
    })
  }

  clickSubmitButton(){
    this.submitButtonTarget.click();
  }
}
