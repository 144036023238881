import { Controller } from 'stimulus';
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['vehicleFields', 'trailerFields', 'axlesSelect',
  'ownerFields','ownerField']
  connect(){
    this.selectAxles = this.axlesSelectTarget
    this.init()
  }

  init() {
    if(this.hasOwnerFieldTarget) {
      let isChecked = this.ownerFieldTargets.filter(item => item.checked)
      this.toggleOwnerFields({currentTarget:{value: isChecked[0].value}})
    }
  }

  toggleFields(e){
    if (e.currentTarget.value == 'vehicle') {
      this.trailerFieldsTarget.classList.add('hidden')
      this.vehicleFieldsTarget.classList.remove('hidden')

      this.disableFields(this.vehicleFieldsTarget, false)
      this.disableFields(this.trailerFieldsTarget, true)
    }else if(e.currentTarget.value == 'trailer'){
      this.vehicleFieldsTarget.classList.add('hidden')
      this.trailerFieldsTarget.classList.remove('hidden')

      this.disableFields(this.trailerFieldsTarget, false)
      this.disableFields(this.vehicleFieldsTarget, true)
    }
  }

  disableFields(container, option){
    let inputs = container.querySelectorAll('input, select')
    inputs.forEach(function(input) {
      input.disabled = option
    })
  }

  cleanFields(container){
    let inputs = container.querySelectorAll('input, select')
    let span = container.querySelector('span.value');
    inputs.forEach(function(input){
      input.value = ''
    })
    span.innerHTML = null
  }

  toggleAxles(e){
    this.cleanSelectAxles()
    if(e.currentTarget.value == 'semi_trailer'){
      this.createOptions(['',1,2,3])
    }else{
      this.createOptions(['',2,3,4])
    }
  }

  toggleOwnerFields(e){
    let value = e.currentTarget.value
    if (value == 'own') {
      this.ownerFieldsTarget.classList.add('hidden')
      this.disableFields(this.ownerFieldsTarget, true)
      this.cleanFields(this.ownerFieldsTarget, true)
    }else{
      if (value == "lessee")
        this.ownerFieldsTarget.querySelector('p')
                              .innerHTML = 'Datos de arrendatario'
      if (value == "owner")
        this.ownerFieldsTarget.querySelector('p')
                              .innerHTML = 'Datos de propietario'

      this.ownerFieldsTarget.classList.remove('hidden')
      this.disableFields(this.ownerFieldsTarget, false)

    }
  }

  createOptions(array){
    let select = this.selectAxles

    array.forEach(function(index) {
      var option = document.createElement('option')
      option.value = index
      option.text = index
      select.add(option)
    })
  }

  cleanSelectAxles(){
    var i, L = this.selectAxles.length - 1;
    for(i = L; i >= 0; i--) {
      this.selectAxles.remove(i);
    }
  }
}
