import { Controller } from 'stimulus';
import MicroModal from "micromodal"
import Plyr from 'plyr';

export default class extends Controller {
  static values = {
    type: String,
    id: String
  }
  connect() {
    this.showModal()
  }

  showModal() {
    const url = `/api/wizards`
    fetch(url)
      .then(response => response.json())
      .then((data) => {
        if (this.typeValue == 'config' && !data.wizard.configuration) {
          MicroModal.show(this.idValue)
          this.showWelcomeVideo()
        }
        if (this.typeValue == 'freights') {
          this.redirectCatalogs(data)
        }
      })
  }

  redirectCatalogs(data) {
    let btn = this.element.querySelector('.btn-edit')
    const { shipping_contracts, locations, businesses, clients, employees, vehicles } = data.wizard

    const urls = [
      {
        url: '/vehicles',
        flag: vehicles
      },
      {
        url: '/employees',
        flag: employees
      },
      {
        url: '/clients',
        flag: clients
      },
      {
        url: '/locations',
        flag: locations
      },
      {
        url: '/businesses',
        flag: businesses
      },
      {
        url: '/shipping_contracts',
        flag: shipping_contracts
      }

    ]
    let url = urls.filter(item => item.flag != true)
    if (url.length === 0) { return true }
    btn.href = url[0].url
    MicroModal.show(this.idValue)
  }

  showWelcomeVideo(){
    this.welcomeVideo = new Plyr('#welcome_video')
  }

  closeModal() {
    this.welcomeVideo.stop()
    MicroModal.close(this.idValue)
  }
}