import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [
    'clientLabel', 'shippingContractLabel', 'materialTypeLabel',
    'client', 'route', 'stopTemplate', 'routePath', 'routeName', 'localTrip',
    'importExportField', 'importField'
  ]

  connect(){
    console.log('generalForm')
  }

  updateRoute(e) {
    let object = e.detail.object
    this.clientLabelTarget.innerHTML = object.shipping_contract_client_name
    this.shippingContractLabelTarget.innerHTML = object.shipping_contract_folio
    this.materialTypeLabelTarget.innerHTML = object.shipping_contract_material_type_i18n
    this.materialTypeLabelTarget.classList.add(object.shipping_contract_material_type_i18n == 'Normal' ? 'bg-naviioBlue' : 'bg-naviioRed')
    this.clientTarget.value = object.client_id
    this.routeTarget.value = object.id
    this.routeNameTarget.innerHTML = object.name

    let routeType = Object.keys(object.route_type)[0]
    this.toggleCustomsFields(routeType)

    let stops = object.stops_names.split('|')
    this.routePathTarget.innerHTML = ''
    let i = 1
    for(const stop of stops){
      let temp = this.stopTemplateTarget.cloneNode(true)
      temp.querySelector('p').innerHTML = stop
      temp.querySelector('h1').innerHTML = i
      temp.classList.remove('hidden')
      this.routePathTarget.append(temp)
      i++;
    }
  }

  toggleCustomsFields(routeType) {
    this.toggleImportFields(routeType)

    let toggle = routeType == 'import' || routeType == 'export'
    console.log('route type:', routeType, toggle)

    for(const container of this.importExportFieldTargets){
      container.classList.toggle('hidden', !toggle)
      if(!toggle){
        for(const input of container.querySelectorAll('input')){
          input.value = ''
        }
        for(const input of container.querySelectorAll('select')){
          input.value = ''
        }
      }
    }
  }

  toggleImportFields(routeType) {
    let toggle = routeType == 'import'
    this.importFieldTarget.classList.toggle('hidden', !toggle)
  }

  localTripAlert(e){
    let object = e.detail.object;
    let localTrip = object.local_trip;

    if(localTrip) { this.localTripTarget.classList.remove('hidden') }
  }
}
