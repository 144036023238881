import { Controller } from "stimulus"
import tippy from "tippy.js"

export default class extends Controller {
  static values = {
    view: String,
    page: String,
    content: String,
    action: String
  }
  connect() {
    this.contentValue ? this.createTooltip() : this.createTooltips()
  }

  async createTooltips() {
    const url = `/tooltips/${this.viewValue}.json`
    const dataTooltips = await fetch(url).then(response => response.json())

    dataTooltips[this.pageValue].forEach((item) => {
      let element = document.querySelectorAll(item.element)
      if (element == null) {
        return true
      }
      tippy(element, item.properties);
    })
  }

  createTooltip() {
    tippy(this.element,
      {
        content: this.contentValue,
        trigger: this.actionValue ? this.actionValue : "click"
      });
  }
}
