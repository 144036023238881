import { Controller } from "stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static targets = [
    'uuidContainer', 'uuid', 'code'
  ]

  connect() {
    console.log('cancel connected')
    this.uuidSelect = new SlimSelect({
      select: this.uuidTarget,
      addable: function(value) {
        return value;
      }
    })

    this.codeChanged()
  }

  codeChanged(){
    let val = this.codeTarget.value
    console.log(val)
    if(val == '01') {
      this.uuidContainerTarget.classList.remove('hidden')
    } else {
      this.uuidContainerTarget.classList.add('hidden')
      this.uuidSelect.set(null)
    }
  }
}
