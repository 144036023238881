import { Controller } from "stimulus"
import SlimSelect from "slim-select"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'taxRate', 'factor', 'fee', 'base', 'feeAmount', 'taxName', 'taxType',
    'taxCode', 'feeDummy', 'description'
  ]

  connect() {
    this.selectedTax = null
    this.concept = this.element.closest('.concept')
    this.baseElement = this.concept.querySelector('.base')

    this.baseTarget.value = this.getBaseAmount()
  }

  taxSelected(){
    const option = this.taxRateTarget.options[this.taxRateTarget.selectedIndex]
    this.selectedTax = option.dataset


    let indexValue = this.element.dataset.incomes === 'true' ? 1 : 0
    if (this.hasFactorTarget) {
      this.fillFactorTypes(JSON.parse(this.selectedTax.factors), indexValue)
    }
    this.feeTarget.value = this.selectedTax.maximumValue
    if (this.hasFeeDummyTarget) {
      if(this.factorTarget.value == 'rate' || this.factorTarget.value == 'Tasa') {
        this.feeDummyTarget.value = (this.selectedTax.maximumValue * 100)
        this.maskFeeDummy(true)
      } else {
        this.feeDummyTarget.value = this.selectedTax.maximumFixedValue
      }
    }
    if (this.hasDescriptionTarget) {
      this.descriptionTarget.value = option.text
    }
    if (this.hasTaxNameTarget) {
      this.taxNameTarget.value = this.selectedTax.type
    }
    this.taxTypeTarget.value = this.selectedTax.transfer ? 'transfer' : 'retain'
    this.taxCodeTarget.value = this.selectedTax.tax
    if(this.hasFactorTarget) {
      this.calculate()
    }
  }

  factorChanged(){
    if(this.factorTarget.value == 'rate' || this.factorTarget.value == 'Tasa') {
      this.feeDummyTarget.value = (this.selectedTax.maximumValue * 100)
      this.maskFeeDummy(true)
    } else {
      this.feeDummyTarget.value = this.selectedTax.maximumFixedValue
      this.feeTarget.value = this.selectedTax.maximumFixedValue
    }

    this.calculate()
  }

  maskFeeDummy(silence){
    let val = this.feeDummyTarget.value.replace(/[^0-9.]/g, '')
    val = parseFloat(val)
    if(this.factorTarget.value == 'rate' || this.factorTarget.value == 'Tasa') {
      console.log('assigned fee as tasa', val, val / 100)
      this.feeDummyTarget.value = val.toFixed(2) + ' %';
      this.feeTarget.value = val / 100
    } else {
      console.log('assigned fee as couta', val, val)
      this.feeDummyTarget.value = val
      this.feeTarget.value = val
    }
    if(silence === true) return;

    this.calculate()
  }

  calculate(){
    let factor = this.factorTarget.value
    let fee= this.feeTarget.value
    console.log('calculate tax', fee, factor)
    if(fee == '' || factor == ''){
      this.feeAmountTarget.value = 0
      return;
    }

    let total = 0;
    if(factor == 'rate' || factor == 'Tasa') {
      total = (fee * 100) * (this.baseTarget.value * 100)
      this.feeAmountTarget.value = (total / 10000).toFixed(2)
    } else if (factor == 'amount') {
      this.feeAmountTarget.value = fee
    }
    this.feeAmountTarget.dataset.taxType = this.taxTypeTarget.value

    this.triggerGlobalCalculation()
  }

  fillFactorTypes(types, indexValue){
    this.factorTarget.innerHTML = ''
    for(const type of types) {
      const option = `<option value='${type[indexValue]}'>${type[1]}</option>`
      this.factorTarget.innerHTML += option
    }
    this.factorTarget.value = types[0][indexValue]
  }

  getBaseAmount(){
    return this.baseElement.value
  }

  triggerGlobalCalculation(){
    const event = new CustomEvent('calculate',{
      bubbles: true
    })
    this.element.dispatchEvent(event)
    console.log('dispatched global event')
  }

  autoSelect(e) {
    let input = e.currentTarget
    input.setSelectionRange(0, input.value.length)
  }
}
